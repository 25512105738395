/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
// import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { PageTitle } from "../../../_metronic/layout/core";
import axios from "axios";
import { Link } from "react-router-dom";

const DashboardPage = (props) => (
  <>
    {/* begin::Row */}
    <div className="row g-5 g-xl-10 mb-5 mb-xl-10"></div>
  </>
);

const DashboardWrapper = () => {
  const intl = useIntl();
  const [stats, setStats] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("kt-auth-react-v.api_token");
    axios
      .get(`${process.env.REACT_APP_API_URL}/adminuser/dashboard-stats`, {
        headers: { Authorization: ` ${token}` },
      })
      .then((res) => {
        setStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage stats={stats} />
    </>
  );
};

export { DashboardWrapper };
