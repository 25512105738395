import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";

const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
const ServicePage = lazy(() => import("../pages/services/ServicesWrapper"));
const CategoryPage = lazy(() => import("../pages/category/CategoryWrapper"));
const ProductPage = lazy(() => import("../pages/product/ProductWrapper"));
const CompanyEmployeePage = lazy(
  () => import("../pages/manageUsers/companyEmployee/CompanyEmployeeWrapper")
);
const ConsumerPage = lazy(
  () => import("../pages/manageUsers/consumer/ConsumerWrapper")
);
const ProviderPage = lazy(
  () => import("../pages/manageUsers/provider/ProviderWrapper")
);

const BookingPage = lazy(() => import("../pages/booking/BookingWrapper"));
const BookingDetailPage = lazy(
  () => import("../pages/booking/BookingDetailWrapper")
);

const SettingPage = lazy(() => import("../pages/setting/SettingWrapper"));

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="services" element={<ServicePage />} />
        <Route path="service/:serviceId/category" element={<CategoryPage />} />
        <Route path="products" element={<ProductPage />} />
        <Route
          path="manage-user/company-employee"
          element={<CompanyEmployeePage />}
        />
        <Route path="manage-user/consumer" element={<ConsumerPage />} />
        <Route path="manage-user/provider" element={<ProviderPage />} />
        <Route path="booking" element={<BookingPage />} />
        <Route path="booking/:bookingId" element={<BookingDetailPage />} />
        <Route path="setting" element={<SettingPage />} />
        {/* Lazy Modules */}
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
